<template>
  <div class="relative flex flex-column min-w-screen max-w-screen min-h-screen overflow-x-hidden">
    <Navbar/>
    <div class="flex-1">
      <slot/>
    </div>
    <Footer/>
    <PipedriveContactModal/>
    <!--    <MedicoSocialModal/>-->
    <!--    <Alert/>-->
    <Analytics/>
  </div>
</template>


<script setup lang="ts">
</script>
